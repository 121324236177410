import "../../../../css/modules/triptych.scss";
import Swiper, { Navigation, Autoplay } from "swiper";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/autoplay";

export function loadLastReviews() {
  const swiper = new Swiper("#reviews", {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 50,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
}

export function loadLastNews() {
  const swiper = new Swiper("#news", {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 50,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
}

export function loadLastSocial() {
}
