import "../css/home.scss";
import { loadSlideMarques } from "./components/scrollmarque.js";
import { loadLastReviews, loadLastNews, loadLastSocial } from "./modules/oryx/front/hometriptych.js";
import { loadBestSelling } from "./modules/oryx/front/homebestselling.js";
import { loadNewProducts } from "./modules/oryx/front/homenewproducts.js";

import Swiper, { Navigation, Autoplay, EffectFade } from "swiper";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/autoplay";
import "swiper/scss/effect-fade";

import "./modules/ps_emailsubscription/ps_emailsubscription.js";

$(document).ready(function () {

  if ($("#bloc_marques").length) {
    loadSlideMarques();
  }
  if ($("#reviews").length) {
    loadLastReviews();
    $('#reviews').find('blockquote p').each(function (i, item) {

      let truncate = 250;
      if ($(window).width() < 1024) {
        truncate = 160;
      }

      const quoteText = $(item).html();
      if (quoteText.length > truncate) {
        $(item).text(quoteText.substring(1, truncate) + '..');
      }
    });
  }
  if ($("#news").length) {
    loadLastNews();
  }

  if ($("#news").length) {
    loadLastSocial();
  }



  if ($("#bestselling").length) {
    loadBestSelling();
  }
  if ($("#newproducts").length) {
    loadNewProducts();
  }

  const swiper = new Swiper("#pshomeslider", {
    modules: [Navigation, Autoplay, EffectFade],
    rewind: true,
    autoplay: {
      delay: 3000,
    },
    autoHeight: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    on: {
      init: function () {
        if ($(window).height() > $(window).width() && $(window).width() < 1024) {
          $('#pshomeslider').find('.swiper-slide').css({
            "height": "312px"
          });
        }
        else {
          const width = $('#pshomeslider').find('.swiper-slide-active').width();
          const ratio = 2400 / width;
          const height = 800 / ratio;
          $('#pshomeslider').find('.swiper-slide img').css({
            "height": height + "px"
          });
        }
      }
    }
  });
});
