import "../../../../css/modules/newproducts.scss";
import Swiper, { Navigation, Autoplay } from "swiper";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/autoplay";

export function loadNewProducts() {
  const swiper = new Swiper("#newproducts", {
    modules: [Navigation, Autoplay],
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      1280: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1680: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });
}
