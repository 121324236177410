import "../../css/modules/scrollmarque.scss";
import Swiper, { Navigation, Autoplay } from "swiper";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/autoplay";

export function loadSlideMarques() {
  var swiper = new Swiper("#bloc_marques", {
    modules: [Navigation, Autoplay],
    autoplay: {
      delay: 3000,
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 7,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      1280: {
        slidesPerView: 8,
        spaceBetween: 20,
      },
      1680: {
        slidesPerView: 12,
        spaceBetween: 20,
      },
    },
  });
}
